<template>
  <div class="d-flex justify-center flex-column">
    <div v-if="passedData.dialogType === 'reject'" class="icon-wrapper">
      <div v-if="passedData.dialogIcon" class="d-flex justify-center">
        <component :is="iconComponent" color="#888" :size="80" />
      </div>
    </div>
    <div v-else-if="passedData.dialogType === 'accept'" class="icon-wrapper">
      <div v-if="passedData.dialogIcon" class="d-flex justify-center">
        <component :is="iconComponent" color="#888" :size="80" />
      </div>
    </div>
    <div class="d-flex justify-space-between align-center flex-column my-3">
      <block-text
        :text="passedData.text1"
        color="primary"
        align="center"
        type="headline"
      />
      <block-text
        :text="passedData.text2"
        color="secondary"
        align="center"
        type="body-1"
        class="mt-5"
      />
    </div>
    <v-divider class="mt-5"></v-divider>
    <div class="d-flex justify-space-between align-top py-5 payment-info">
      <div class="d-flex flex-column">
        <block-text
          :text="passedData.transaction.type"
          color="secondary"
          type="body-1"
        />
        <div class="d-flex align-center mt-3">
          <v-avatar color="rgba(190, 191, 192, 0.25)" size="40">
            <v-img
              v-if="passedData.transaction.userImage"
              max-width="40"
              :src="passedData.transaction.userImage"
            />
            <ph-user v-else :size="24" :color="'rgba(128, 128, 129, 1)'" />
          </v-avatar>
          <block-text
            :text="passedData.transaction.user"
            color="primary"
            type="body-1"
            weight="bold"
            class="ml-3"
          />
        </div>
      </div>
      <div class="d-flex flex-column align-end">
        <block-text
          :text="$t('dialog.amount')"
          color="secondary"
          type="body-1"
        />
        <div class="d-flex align-center mt-3">
          <block-text
            :text="passedData.transaction.amount.toFixed(2)"
            color="secondary"
            type="title"
            class="mr-3"
          />
          <v-img
            width="40"
            height="40"
            :src="require('@/assets/ttuToken.svg')"
          />
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="py-7 mt-2">
      <v-textarea
        v-model="responseNotes"
        :label="$t('dialog.enterMessage')"
        :counter="360"
        :rules="[
          (v) =>
            (v || '').length <= 360 ||
            'Description must be 360 characters or less',
        ]"
        auto-grow
        rows="1"
        no-resize
        outline
        weight="bold"
      />
    </div>
    <div
      class="mt-3 d-flex justify-end"
      v-if="passedData.dialogType === 'reject'"
    >
      <v-btn
        @click="rejectTTU(passedData.transaction)"
        outlined
        rounded
        :elevation="0"
        text
        :loading="rejectTTULoading"
        :color="'primary'"
        :ripple="false"
        :plain="false"
        class="text-capitalize body-1 px-10 font-weight-bold text-center"
        >{{ passedData.ctaText }}
        <span slot="loader" class="button-loader">
          <v-progress-circular
            :size="24"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </span>
      </v-btn>
    </div>
    <div
      v-else-if="passedData.dialogType === 'accept'"
      class="mt-3 d-flex justify-end"
    >
      <v-btn
        @click="acceptTTU(passedData.transaction)"
        outlined
        rounded
        :elevation="0"
        text
        :loading="acceptTTULoading"
        :color="'primary'"
        :ripple="false"
        :plain="false"
        class="text-capitalize body-1 px-10 font-weight-bold text-center"
        >{{ passedData.ctaText }}
        <span slot="loader" class="button-loader">
          <v-progress-circular
            :size="24"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { PhUser } from "phosphor-vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PhUser,
  },
  props: {
    passedData: {
      default: null,
    },
  },
  data() {
    return {
      iconComponent: null,
      responseNotes: "",
    };
  },
  async mounted() {
    this.componentLoader();
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
      acceptTTULoading: "wallet/acceptTTULoading",
      rejectTTULoading: "wallet/rejectTTULoading",
      currentDateFilter: "wallet/currentDateFilter",
      activeFilter: "wallet/activeFilter",
    }),
  },
  methods: {
    async rejectTTU(transaction) {
      await this.$store.dispatch("wallet/rejectTTU", {
        walletID: transaction.walletId,
        transferID: transaction.userTransferID,
        responseNotes: this.responseNotes,
      });
      this.$eventHub.$emit("toggleDialog", {
        isOpen: false,
      });
      this.getActivities();
    },
    async acceptTTU(transaction) {
      await this.$store.dispatch("wallet/acceptTTU", {
        walletID: transaction.walletId,
        transferID: transaction.userTransferID,
        responseNotes: this.responseNotes,
      });
      this.$eventHub.$emit("toggleDialog", {
        isOpen: false,
      });
      this.getActivities();
    },
    async componentLoader() {
      if (this.passedData.dialogIcon) {
        let res = await (() => import("phosphor-vue"))``;
        this.iconComponent = res[this.passedData.dialogIcon];
      }
    },
    getActivities() {
      let dateFrom = new Date();

      if (isNaN(this.currentDateFilter)) {
        dateFrom = "";
      } else {
        dateFrom.setDate(dateFrom.getDate() - this.currentDateFilter);
      }

      if (this.passedData.singleTable) {
        this.$store.dispatch("wallet/getUserActivity", {
          filter: this.activeFilter,
          direction: this.passedData.direction,
          skip: 0,
          limit: 10,
          dateFrom: dateFrom.toLocaleString("en-US"),
          dateTo: new Date().toLocaleString("en-US"),
        });
      } else {
        this.$store.dispatch("wallet/getUserActivityLast30Days", {
          filter: this.activeFilter,
          direction: this.passedData.direction,
          skip: 0,
          limit: 4,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  svg {
    width: 24px;
    height: 24px;
  }
}

.payment-info {
  width: 100%;
}

::v-deep .v-textarea {
  padding: 0;
  margin: 0;
  textarea {
    color: var(--v-primary-base);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.33;
    min-height: 0px;
  }
  .v-label {
    top: -2px;
  }
}
</style>
